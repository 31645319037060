import axios from 'axios';
import useWebSocket from 'react-use-websocket';
  
  
  
// const sendQuestionsToInputScanner = async (questions, currentUsername, action) => {
//   const databaseApi = process.env.REACT_APP_IMAGE_SCANNER;
// //   console.log( "questions insendQuestionsToInputScanner", questions)
//   //questions is an array of strings?

  
//   if (questions && questions.length > 0) {
//     try {
      
//       const params = {
//         data: {
//           action: action,
//           username: currentUsername,
//           payload: questions.map(question => ({
//             userQuestion: question
//       }))
//       }
//       } ;
      
// //      console.log( "THIS is the final object that is being sent: ", params)
// //      console.log( "This is the database api", databaseApi)
    
//       const response = await axios.post(databaseApi, params);
      
// //       console.log('sendQuestionsToInputScanner, response.data:', response.data)
      
// //       console.log( 'Received API response:', response.data);
//       return response.data; // Return the response data for further processing
//     } catch (error) {
//         console.error("Error fetching data:", error);
//         // Check if the error object has a response and a status code
//         if (error.response && error.response.status) {
//           const statusCode = error.response.status;
// //           console.log( `Received status code: ${statusCode}`);
          
//           // Check for the 429 status code
//           if (statusCode === 429) {
//             alert('Too many requests. Please try again after 3 hours.');
//           } else if (statusCode === 500) {
//             alert('Server error. Please try again later.');
//           }
//           // Add more conditions as needed for different status codes
//         } else {
//           // Handle cases where the error may not be due to an HTTP response
//           alert('An unexpected error occurred. Please try again.');
//         }
//         throw error; // You can still throw the error after handling it if you want the calling code to be aware
//       }
//   } else {
// //      console.log( "No questions to send");
//     return null; // Indicate no operation if no questions
//   }
// };



// const sendQuestionsToProblemSolver = async (questions, currentUsername, action, userEnteredTopic) => {
//   //problem solver directly puts questions to DB
//   const problemSolverApi = process.env.REACT_APP_PROBLEM_SOLVER;

  
  
  
//     if (questions && questions.length > 0) {
//     try {
      
//       //this params object must not have any strings with double quotes
//       // I tried sending this string " 'text' " and Rest-api in api gateway returned an error.
//       const params = {
//         data: {
//           action: action,
//           payload: questions.map(question => ({
//             userEnteredTopic: userEnteredTopic.replace(/^['"]+|['"]+$/g, ''),
//             username: currentUsername,
//             userQuestion: question.replace(/^['"]+|['"]+$/g, '')
//           }))
//         }
//       };
      
// //        console.log("THIS is the final object that is being sent: ", params)
// //       console.log( "This is the database api", databaseApi)
    
//       const response = await axios.post(problemSolverApi, params);
// //       console.log( 'Received API response:', response.data);
      
//       return true;
      
//     } catch (error) {
//       console.error("Error while submitting the questions:", error);
//       throw error; // Rethrow or handle error as needed
//     }
//   } else {
// //     console.log( "No questions to send");
//     return false; // Indicate no operation if no questions
//   }
  
  
  
// };



// communicationWithAIServices.js
// Provides a custom hook to interact with WebSocket for AI communications.

const useAIWebsocket = () => {
  const socketUrl = process.env.REACT_APP_WEBSOCKET;
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: () => true, // Auto-reconnect policy
    onError: (event) => {
      // Do nothing or handle the error silently
    },
    onClose: (event) => {
      // Prevent the browser from logging the warning
      event.stopImmediatePropagation();
    }
  });

  return {
    sendMessage,
    lastMessage,
    readyState
  };
};





export {useAIWebsocket};


