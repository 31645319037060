// dataTransferServices.js

import axios from 'axios';

export const dataManagerPingPong = async (actionStr) => {
  const databaseApiUrlStr = process.env.REACT_APP_DATA_API;
  
  if (actionStr === 'ping') {
    try {
      const requestDataObj = { actionStr: 'ping' };
      const responseObj = await axios.post(databaseApiUrlStr, requestDataObj);
      const responseDataStr = responseObj.data;
//       console.log("Response Data: ", responseDataStr, "Type: ", typeof(responseDataStr) );
      return responseDataStr;
    } catch (error) {
      console.error("Error during ping action: ", error);
      throw error;
    }
  }
};

export const fetchAllDistinctTopicNames = async (usernameStr) => {
  const databaseApiUrlStr = process.env.REACT_APP_DATA_API;

  try {
    const requestDataObj = {
      actionStr: 'fetchAllDistinctTopicNames',
      usernameStr: usernameStr,
    };
    
    const responseObj = await axios.post(databaseApiUrlStr, requestDataObj);
    const topicsArrStr = responseObj.data;
    return topicsArrStr;
  } catch (error) {
    console.error("Error while fetching topic names:", error);
    throw error;
  }
};

export const fetchSingleTopicInformation = async (usernameStr, problemTopicStr) => {
  const databaseApiUrlStr = process.env.REACT_APP_DATA_API;

  try {
    const requestDataObj = {
      actionStr: 'fetchSingleTopicInformation',
      usernameStr: usernameStr,
      problemTopicStr: problemTopicStr
    };
    const responseObj = await axios.post(databaseApiUrlStr, requestDataObj);
    const topicInfoArrObj = responseObj.data;
    return topicInfoArrObj;
  } catch (error) {
    console.error("Error fetching topic information:", error);
    throw error;
  }
};

export const createDatabaseItem = async (usernameStr, itemCategoryStr, newTopicStr) => {
  const databaseApiUrlStr = process.env.REACT_APP_DATA_API;
  
  try {
    const requestDataObj = {
      actionStr: 'createDatabaseItem',
      usernameStr: usernameStr,
      itemCategoryStr: itemCategoryStr,
      problemTopicStr: newTopicStr
    };
    const responseObj = await axios.post(databaseApiUrlStr, requestDataObj);
    const creationResultObj = responseObj.data;
    return creationResultObj;
  } catch (error) {
    console.error("Error creating a new database item:", error);
    throw error;
  }
};




export const syncUserUploadedImagesWithS3 = async (imageObjectsArr, operationStr, usernameStr, currentTopicStr) => {
//   console.log("Image Objects: ", imageObjectsArr);

  const databaseApiUrlStr = process.env.REACT_APP_DATA_API;

  try {
    const requestDataObj = {
      actionStr: 'syncUserUploadedImagesWithS3',
      imageObjectsArr,
      operationStr,
      usernameStr,
      problemTopicStr:currentTopicStr
    };
    const responseObj = await axios.post(databaseApiUrlStr, requestDataObj);
    const syncResultObj = responseObj.data;
    return syncResultObj;
  } catch (error) {
    console.error("Error syncing S3 images:", error);
    throw error;
  }
};
