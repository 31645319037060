// AppStateContext.js
import React, { createContext, useState, useMemo } from 'react';

// Creating the context for application state
export const AppStateContext = createContext();

// Provider component for AppState context
export const AppStateProvider = ({ children }) => {
  const [currentPageStr, setCurrentPageStr] = useState('');
  const [isProblemsUploadedBool, setIsProblemsUploadedBool] = useState(true);

  // Memoize the context value to only recompute if dependencies change
  const value = useMemo(() => ({
    currentPageStr, 
    setCurrentPageStr, 
    isProblemsUploadedBool, 
    setIsProblemsUploadedBool
  }), [currentPageStr, isProblemsUploadedBool]);

  // Providing state and updater functions to consumers using memoized value
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};
