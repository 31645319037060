//index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';



// Import new context providers
import { AppStateProvider } from './contextVariables/AppStateContext';
import { LearningProvider } from './contextVariables/LearningContext';
import { UserProvider } from './contextVariables/UserContext';



console.error = function() {}; // No-op function to prevent logging errors
console.warn = function() {};  // No-op function to prevent logging warnings


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppStateProvider> {/* AppStateProvider wraps all contexts */}
      <UserProvider> {/* UserProvider, potentially replacing CurrentUserContextProvider */}
        <LearningProvider> {/* LearningProvider potentially replacing or encompassing CurrentTopicContextProvider */}
          <App />
        </LearningProvider>
      </UserProvider>
    </AppStateProvider>
  </React.StrictMode>
);

reportWebVitals();
