import React, { createContext, useState, useMemo } from 'react';

// Creating the context for user data
export const UserContext = createContext();

// Provider component for User context
export const UserProvider = ({ children }) => {
  const [currentUsernameStr, setCurrentUsernameStr] = useState('');

  // Memoize the context value to prevent unnecessary re-renders
  const value = useMemo(() => ({
    currentUsernameStr,
    setCurrentUsernameStr
  }), [currentUsernameStr]);

  // Providing state and updater functions to consumers using memoized value
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};
