import React, { createContext, useState, useMemo } from 'react';

// Creating the context for managing learning materials and topics
export const LearningContext = createContext();

// Provider component for Learning context
export const LearningProvider = ({ children }) => {
  const [currentTopicStr, setCurrentTopicStr] = useState('');
  const [currentExerciseDetailsObj, setCurrentExerciseDetailsObj] = useState({}); // Maintain state for current exercise details
  const [problemCountSnapshotInt, setProblemCountSnapshotInt] = useState(0); // Snapshot of the current number of problems

  // Memoize the context value to prevent unnecessary re-renders
  const value = useMemo(() => ({
    currentTopicStr, 
    setCurrentTopicStr, 
    currentExerciseDetailsObj, 
    setCurrentExerciseDetailsObj,
    problemCountSnapshotInt, 
    setProblemCountSnapshotInt
  }), [currentTopicStr, currentExerciseDetailsObj, problemCountSnapshotInt]);

  // Providing state and updater functions to consumers using memoized value
  return (
    <LearningContext.Provider value={value}>
      {children}
    </LearningContext.Provider>
  );
};
