// App.mjs
import React, {useState, useContext, useEffect } from 'react';
import './App.css';
import { AppStateContext } from './contextVariables/AppStateContext';
import { UserContext } from './contextVariables/UserContext';

import { LoginPage } from './components/LoginPage/LoginPage';
import { Navigation } from './components/Navigation/Navigation';
import { GeneralChatPage } from './components/GeneralChatPage/GeneralChatPage';
import { MainWorkBoardPage } from './components/MainWorkBoardPage/MainWorkBoardPage';
import { ProblemUploadIndicator } from './components/ProblemUploadIndicator/ProblemUploadIndicator';
import { dataManagerPingPong } from './services/dataTransferServices';

const App = () => {
  const { currentPageStr, isProblemsUploadedBool } = useContext(AppStateContext);
  const { currentUsernameStr } = useContext(UserContext);
  
  const [currentTopicProblemsArrObj, setCurrentTopicProblemsArrObj] = useState([])

  // Effect for initiating the ping service once on component mount
  useEffect(() => {
    dataManagerPingPong('ping');
  }, []);
  
//   console.log("App rendered");

  return (
    <div className="app">
      {currentUsernameStr === "" ? (
          <LoginPage />
      ) : (
          <>
            <div className="navigation">
              <Navigation 
              currentTopicProblemsArrObj={currentTopicProblemsArrObj}
              setCurrentTopicProblemsArrObj={setCurrentTopicProblemsArrObj}
              />
            </div>
              
            <div className="mainContent" style={{ marginLeft: '25%' }}>
              {currentPageStr === "generalChat" && <GeneralChatPage />}
              {currentPageStr === "learn" && (
                  <MainWorkBoardPage currentTopicProblemsArrObj={currentTopicProblemsArrObj}/>
              )}
              {!isProblemsUploadedBool && (
                  <ProblemUploadIndicator 
                  currentTopicProblemsArrObj={currentTopicProblemsArrObj}
                  />
              )}
            </div>
          </>
      )}
    </div>
  );
};

export default App;
