import React, { useState, useContext, useEffect } from 'react';
import './MainWorkBoardPage.css';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import { QuicklyUploadQuestions } from './QuicklyUploadQuestions/QuicklyUploadQuestions';
import { ExerciseDisplay } from './ExerciseDisplay/ExerciseDisplay';
import { UserSolutionToTheProblem } from './UserSolutionToTheProblem/UserSolutionToTheProblem';
import { AISolutionToTheProblem } from './AISolutionToTheProblem/AISolutionToTheProblem';
import { LearningContext } from '../../contextVariables/LearningContext';

export const MainWorkBoardPage = ({ currentTopicProblemsArrObj }) => {
  const [isAIToggleEnabledBool, setIsAIToggleEnabledBool] = useState(false); // Boolean state to toggle AI solution view.
  const [isAnswerVisibleBool, setIsAnswerVisibleBool] = useState(false); // Boolean state to toggle the display of the correct answer.
  const [showUserSolution, setShowUserSolution] = useState(false); // State to control delayed render
  const { currentExerciseDetailsObj } = useContext(LearningContext);

  // Introduce a delay for rendering the UserSolutionToTheProblem component
  useEffect(() => {
    if (!isAIToggleEnabledBool) {
      const timer = setTimeout(() => {
        setShowUserSolution(true);
      }, 1000); // 1000ms delay (1 second)

      return () => clearTimeout(timer); // Cleanup the timer on unmount or if toggle changes
    } else {
      setShowUserSolution(false); // Hide UserSolutionToTheProblem if AI solution is toggled
    }
  }, [isAIToggleEnabledBool]);

  useEffect(() => {
//     console.log('MainWorkBoardPage rendered');
  }, []);

  return (
    <section className="main-section">
      <QuicklyUploadQuestions />
      <ExerciseDisplay currentTopicProblemsArrObj={currentTopicProblemsArrObj} />

      {currentExerciseDetailsObj && (
        <>
          {isAIToggleEnabledBool ? (
            <AISolutionToTheProblem />
          ) : (
            showUserSolution && <UserSolutionToTheProblem />
          )}
          
          <div className="spacer"></div>

          <div className="buttons-container">
            <button onClick={() => setIsAIToggleEnabledBool(!isAIToggleEnabledBool)}>
              {isAIToggleEnabledBool ? "Type your own answer" : "See step-by-step solution"}
            </button>
            <button onClick={() => setIsAnswerVisibleBool(!isAnswerVisibleBool)}>
              {isAnswerVisibleBool ? "Hide Correct Answer" : "Show Correct Answer"}
            </button>
          </div>
          
          {isAnswerVisibleBool && (
            <div className="correct-answer">
              <p>Correct Answer: <Latex>{currentExerciseDetailsObj.problemAnswerStr}</Latex></p>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default MainWorkBoardPage;
